import ReCAPTCHA from "react-google-recaptcha";

function RecaptchaButton(setRecaptcha) {
  return (
    <ReCAPTCHA
      sitekey="6Ld5GZwqAAAAAB33wMjqyHJPx2cxQEtynJdkk7Me"
      onChange={(val) => {
        setRecaptcha(val);
        console.log("reCAPTCHA token:", val);
      }}
    />
  );
}

export default RecaptchaButton;
