import { useEffect, useState } from "react";
import { getPostById } from "../api";
import { useLocation } from "react-router-dom";
import { PopUp } from "../components/UI/PopUp";

export const ArticlePage = ({ post }) => {
  const [isPopedUp, setIsPopedUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [popupShown, setPopupShown] = useState(false);
  const [scrollTriggered, setScrollTriggered] = useState(false);
  const [contentHTML, setContentHTML] = useState(null); // Store HTML string
  const location = useLocation();

  // INITIAL
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const id = location.pathname.split("article/");
        const postId = id[1];
        const result = await getPostById(postId); // Fetch the data
        let text = result.data.content.free.web;

        // Sanitize content (removing picture-in-picture for iframe)
        const sanitizedContent = text.replace(
          "picture-in-picture",
          "picture-in-picture"
        );
        setContentHTML(sanitizedContent); // Save the sanitized HTML string

        // ***** SEO
        // ** set meta tags
        // ** - subject_line
        // ** - slug
        // ** - title
        // ** - subtitle
        // ** - preview_text

        setMetaTags(
          result.data.subject_line,
          result.data.slug,
          result.data.title,
          result.data.subtitle,
          result.data.preview_text
        );
      } catch (error) {
        console.error("Error fetching posts:", error); // Handle error
      } finally {
        setLoading(false); // Stop the loading indicator
      }
    };

    const setMetaTags = (subject_line, slug, title, subtitle, preview_text) => {
      // add meta tag for subject_line
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", subject_line);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.name = "description";
        newMeta.content = subject_line;
        document.head.appendChild(newMeta);
      }

      // add meta tag for slug
      const metaSlug = document.querySelector('meta[name="slug"]');
      if (metaSlug) {
        metaSlug.setAttribute("content", slug);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.name = "slug";
        newMeta.content = slug;
        document.head.appendChild(newMeta);
      }

      // add meta tag for title
      const metaTitle = document.querySelector('meta[name="title"]');
      if (metaTitle) {
        metaTitle.setAttribute("content", title);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.name = "title";
        newMeta.content = title;
        document.head.appendChild(newMeta);
      }

      // add meta tag for subtitle
      const metaSubtitle = document.querySelector('meta[name="subtitle"]');
      if (metaSubtitle) {
        metaSubtitle.setAttribute("content", subtitle);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.name = "subtitle";
        newMeta.content = subtitle;
        document.head.appendChild(newMeta);
      }

      // add meta tag for preview_text
      const metaPreview = document.querySelector('meta[name="preview_text"]');
      if (metaPreview) {
        metaPreview.setAttribute("content", preview_text);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.name = "preview_text";
        newMeta.content = preview_text;
        document.head.appendChild(newMeta);
      }
    };

    if (post == null) {
      let text = post?.content?.free?.web;

      // Sanitize content (removing picture-in-picture for iframe)
      const sanitizedContent = text?.replace(
        "picture-in-picture",
        "picture-in-picture"
      );
      setContentHTML(sanitizedContent);
    } else if (post != null) {
      fetchPost();
    }
  }, []);

  // TRIGGER POP UP TOGGLE
  useEffect(() => {
    let lastScrollY = window.scrollY;
    let isUserScroll = false; // Flag to track user scroll

    // Delay enabling the popup by 1 second (or adjust time as needed)
    const enableScrollDetection = setTimeout(() => {
      isUserScroll = true;
    }, 1000);

    const handleScroll = () => {
      // Check if the page is already scrolled down more than half and prevent automatic scroll up trigger
      const halfPageHeight = document.body.scrollHeight / 2;
      const currentScrollY = window.scrollY;

      if (currentScrollY > halfPageHeight) {
        setIsPopedUp(true); // Toggle the popup only once when user scrolls past half the page
        setScrollTriggered(true); // Prevent further toggling
        togglePopUpOn();
        setPopupShown(true);
      }

      lastScrollY = currentScrollY;
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(enableScrollDetection);
    };
  }, [scrollTriggered]);

  const togglePopUpOn = () => {
    // setIsPopedUp(!isPopedUp);
    // setPopupShown(true); // Mark popup as shown
    const element = document.getElementsByClassName("popup-background")[0];

    if (!popupShown && element.style.display === "none") {
      element.style.display = "block";
    }
  };

  const togglePopUpOff = () => {
    const element = document.getElementsByClassName("popup-background")[0];
    element.style.display = "none";
  };

  if (loading) {
    return (
      <div className="bg-beige-prim article-content pt-20 md:px-20">
        <img
          className="m-auto"
          src="https://media.tenor.com/g6UyWxRljhwAAAAi/missing-head-mind-loading.gif"
        />
      </div>
    );
  }

  return (
    <div className="bg-beige-prim ">
      <PopUp
        // isPopup={isPopedUp}
        togglePopup={togglePopUpOff}
        title="Enjoying this post?"
        subtitle="Join the club to get more content like this delivered to your inbox for free!"
      ></PopUp>
      <div
        className="article-content pt-20 md:px-20"
        dangerouslySetInnerHTML={{ __html: contentHTML }}
      ></div>
    </div>
  );
};
