import SubscribeForm from "../../SubscribeForm";
import { Typography } from "../../UI/Typography/Typography";
import NewsletterImage from "../../../assets/articles/newsletter/newsletter.png";
import DecorImage from "../../../assets/articles/arrow.png";

export const IntroSection = () => {
  return (
    <section className="flex flex-col items-center px-4 md:px-20 max-w-[1200px] m-auto">
      <img src={NewsletterImage} />
      <div>
        <Typography variant="title">NEWSLETTER</Typography>
        <Typography variant="sub-title">
          The notification email that help you beat the rat race and live life
          on your own schedule.
        </Typography>
      </div>
      <SubscribeForm content="SUBSCRIBE" aligns="center" />
      <img src={DecorImage} />
    </section>
  );
};
