import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  ArchiveSection,
  IntroSection,
} from "../components/layout/ArticlesPage";

export const ArticlesPage = ({ allPosts }) => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash; // Get the hash from the URL
    if (hash) {
      const element = document.querySelector(hash);
      const offset = 100;
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - offset, // Scroll to 100px above the element
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <div className="bg-beige-prim pt-20">
      <IntroSection />
      <section
        id="articles_archieve_section"
        className="articles_archieve_section"
      >
        <ArchiveSection allPosts={allPosts} />
      </section>
    </div>
  );
};
