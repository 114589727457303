import { Typography } from "../../UI/Typography";
import FocusImage from "../../../assets/about/focus/Thumbnail_ai_gen.jpg";
import Decoration from "../../../assets/about/6650144.png";

const FocusItems = [
  {
    type: "text",
    heading: "Investing",
    content:
      "From beginner strategies to advanced techniques, discover how to grow your wealth through practical investing tips and money psychology.",
  },
  {
    type: "text",
    heading: "Credit Card",
    content:
      "Learn how to leverage credit cards to maximize rewards and benefits.",
  },
  {
    type: "text",
    heading: "Housing",
    content:
      "Explore insights and strategies on real estate, home buying, and managing housing costs.",
  },
  {
    type: "image",
    src: FocusImage,
  },
  {
    type: "text",
    heading: "Retirement Planning",
    content:
      "Actionable advice on planning for a secure and comfortable retirement.",
  },
  {
    type: "text",
    heading: "Financial Freedom",
    content:
      "Strategies to help you achieve financial independence and live life on your terms.",
  },
];

export const FocusSection = () => {
  return (
    <section className="focus-section pt-10 px-3 lg:px-16 bg-beige-sec">
      <div className="flex flex-col items-center gap-10 max-w-[1200px] m-auto">
        <Typography variant="h1">MY PASSION</Typography>
        <div className="grid gap-4 grid-cols-12 auto-rows-[200px] md:auto-rows-[240px] lg:auto-rows-[300px]">
          {FocusItems.map((item, i) => {
            if (item.type === "text")
              return (
                <div
                  key={i}
                  className={
                    "text-left border-[3px] border-black rounded-xl bg-white p-5 lg:p-8 hover-dark" +
                    (i === 0 || i === 4
                      ? " col-span-12 md:col-span-7"
                      : " col-span-12 md:col-span-5")
                  }
                >
                  <Typography variant="h2">{item?.heading}</Typography>
                  <Typography variant="">{item?.content}</Typography>
                </div>
              );
            else if (item.type === "image")
              return (
                <div
                  key={i}
                  className="image text-left border-[3px] border-black rounded-xl bg-center bg-[length:120%] p-5 hover-red col-span-12 md:col-span-7 hover:bg-[length:100%] "
                  style={{
                    backgroundImage: "url(" + item?.src + ")",
                  }}
                ></div>
              );
          })}
        </div>
        <img src={Decoration} className="ml-auto w-0 md:w-40" />
      </div>
    </section>
  );
};
