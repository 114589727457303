import { useEffect, useState } from "react";
import {
  ContentSection,
  SpreadsheetSection,
  WelcomeSection,
} from "../components/layout/LandingPage";
import { listRecentPosts } from "../api";

export const LandingPage = () => {
  const [recentPosts, setRecentPosts] = useState([]);

  const currentTimestamp = Math.floor(Date.now() / 1000);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // get articles data
        const result = await listRecentPosts();
        const publishPosts = result?.data.filter(post => post.publish_date <= currentTimestamp);
        setRecentPosts(publishPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();
    // console.log(recentPosts);
  }, []);

  return (
    <div className="bg-beige-prim pt-5 md:pt-3">
      <WelcomeSection />
      <ContentSection recentPosts={recentPosts} />
      <SpreadsheetSection />
    </div>
  );
};
