import { Link } from "react-router-dom";
import { Typography } from "../../UI/Typography";
import CreditImage from "../../../assets/landing/tablet.png";
import StockImage from "../../../assets/landing/monitor.png";
import BudgetImage from "../../../assets/landing/laptop.png";
import ArchiveImage from "../../../assets/landing/archive.png";

const ListSpreadsheetsCard = [
  {
    title: "Credit Card Picker",
    image: CreditImage,
    content:
      "Easily track and manage all your credit card payments and balances in one place.",
    action: "GET THIS NOW!",
  },
  {
    title: "Stock Intrinsic Value Calculator",
    image: StockImage,
    content:
      "Quickly find a company’s true worth with my Intrinsic Value Calculator.",
    action: "GET THIS NOW!",
  },
  {
    title: "Budget Tracker ",
    image: BudgetImage,
    content: "Simplify your budgeting and manage your money like a pro!",
    action: "GET THIS NOW!",
  },
];

export const SpreadsheetSection = () => {
  return (
    <div className="flex flex-col items-center justify-start gap-2 p-3 md:px-32 ">
      <img src={ArchiveImage} className="w-[20vw]" />
      <Typography variant="h2">More From Me</Typography>
      <div className="flex flex-col w-full items-center justify-center gap-12 lg:flex-row">
        {ListSpreadsheetsCard.map((item) => {
          return (
            <div
              key={item.title}
              className="flex flex-col items-center justify-between max-w-96 min-h-[400px] md:min-h-[500px] my-5"
            >
              <div className="flex flex-col items-center gap-2">
                <img src={item.image} className="mb-2" />
                <Typography variant="h3">{item.title}</Typography>
                <Typography variant="">{item.content}</Typography>
              </div>
              <Link
                to="/spreadsheets"
                className="text-white hover-red bg-black px-6 py-4 hover:opacity-80 my-5 font-bold"
              >
                <Typography variant="">{item.action}</Typography>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
