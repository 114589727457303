import { useEffect, useState } from "react";
import { ArticlesMockData } from "../../../api/mockData/ArticlesData";
import { Typography } from "../../UI/Typography";
import { FilterSection } from "./FilterSection";
import { PaginationSection } from "./PaginationSection";
import { listAllPosts } from "../../../api";

export const ArchiveSection = ({ allPosts }) => {
  const [allArticles, setAllArticles] = useState(allPosts);
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [contentTags, setContentTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(true);

  // INITIAL
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const result = await listAllPosts();
        // console.log(result.data);
        setAllArticles(result.data);
        setArticles(result.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };
    // console.log(allPosts);
    if (allPosts != null) {
      setLoading(false);
      setAllArticles(allPosts);
      setArticles(allPosts);
    } else if (allPosts == null) {
      fetchPosts();
    }
    // console.log("Init: ", articles);
  }, [9000]);

  // Get content tags
  useEffect(() => {
    const getTags = async () => {
      try {
        // get content tags
        const allTags = allPosts.map((article) => article.content_tags).flat();
        const uniqueTagsArray = Array.from(new Set(allTags));
        setContentTags(uniqueTagsArray);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    getTags();
    // console.log("contentTags: ", contentTags);
  }, [allArticles]);

  // Filter articles by search term and selected tags
  useEffect(() => {
    let filteredArticles = allArticles;

    // Filter by search term if it's not empty
    if (searchTerm) {
      filteredArticles = filteredArticles.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by selected tags if there are any
    if (selectedTags.length > 0) {
      filteredArticles = filteredArticles.filter((article) =>
        selectedTags.every((tag) => article.content_tags.includes(tag))
      );
    }

    // Update the articles state
    setArticles(filteredArticles);
  }, [searchTerm, selectedTags, allArticles]);

  // Update searchTerm with input value
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle tag selection/deselection
  const toggleTagSelection = (tag) => {
    setSelectedTags(
      (prevSelectedTags) =>
        prevSelectedTags.includes(tag)
          ? prevSelectedTags.filter((t) => t !== tag) // Deselect tag
          : [...prevSelectedTags, tag] // Select tag
    );
  };

  if (loading) {
    return (
      <div className="bg-beige-sec pt-5 px-4 md:px-20">
        <img
          className="m-auto"
          src="https://cssbud.com/wp-content/uploads/2022/05/clouds-spinner.gif"
        />
      </div>
    );
  }

  return (
    <section className="bg-beige-sec pt-5 px-4 md:px-20 ">
      <Typography variant="h1">PAST ISSUES</Typography>
      <FilterSection
        searchTerm={searchTerm}
        onInputChange={handleInputChange}
        contentTags={contentTags}
        selectedTags={selectedTags}
        onTagToggle={toggleTagSelection}
      />
      <div className="max-w-[1200px] m-auto">
        <PaginationSection data={articles} />
      </div>
    </section>
  );
};
