import React from "react";
import MagnifyingGlassImage from "../../assets/header/magnifying_glass.svg";
import { Link } from "react-router-dom";

const SearchButton = () => {
  return (
    <Link
      to="/articles#articles_archieve_section"
      className="w-0 aspect-square rounded-full ml-auto hover:bg-white hover:p-0.5 duration-150 lg:w-10"
    >
      <img src={MagnifyingGlassImage} className="" />
    </Link>
  );
};

export default SearchButton;
