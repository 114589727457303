import { Typography } from "../components/UI/Typography";

export const ErrorPage = () => {
  return (
    <div className="bg-beige-prim pt-28 flex flex-col items-center gap-5">
      <div className="max-w-[1200px] m-auto my-10">
        <Typography variant="title">~ 404 ~</Typography>
        <div>
          <Typography variant="h2">OOPS..! Something Went Wrong</Typography>
          <Typography variant="h3">Page Not Found</Typography>
        </div>
        <img
          src="https://cdn-icons-png.flaticon.com/512/8452/8452928.png"
          className="m-auto w-6/12 md:w-4/12"
        />
      </div>
    </div>
  );
};
