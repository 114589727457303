import { Link } from "react-router-dom";
import { Typography } from "../../UI/Typography";

export const ArticlesCard = ({ thumbnail_url, title, preview_text, id }) => {
  return (
    <Link to={`/article/${id}`}>
      <div className="card-container md:m-2 flex flex-col gap-2 bg-white border-[3px] border-black rounded-xl p-3 hover-dark">
        <img
          src={thumbnail_url}
          alt={thumbnail_url}
          className="w-full outline outline-2 rounded-xl"
        />
        <div className="title-container w-full text-start line-clamp-2">
          <Typography variant="h3">{title}</Typography>
        </div>
        <div className="content-container text-start max-h-20">
          <p className="line-clamp-3 text-xl">{preview_text}</p>
        </div>
        <div className="text-start text-red-prim hover:underline hover:underline-offset-2 line-clamp-1">
          <Typography variant="h3">READ MORE . .</Typography>
        </div>
      </div>
    </Link>
  );
};
