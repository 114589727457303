import { Typography } from "../../UI/Typography";
import YouTubeButton from "../../../assets/landing/MoreOnYouTube.svg";
import { CarouselSection } from "./CarouselSection";

export const ContentSection = ({ recentPosts }) => {
  return (
    <div className="content-container max-w-[1450px] m-auto p-3 md:px-32 my-5 md:my-10">
      <div className="youtube-section ">
        <div className="flex flex-col items-center gap-3 m-auto md:w-8/12 lg:w-6/12">
          <Typography variant="h1">Who is Brian?</Typography>
          <Typography>
            My goal is to help you live a richer life while building your wealth
            by mastering the mindset of money, making smart investments, and
            managing your finances right.
          </Typography>
        </div>
        <button className="more-on-youtube hover-red bg-black px-6 py-4 hover:opacity-80 my-5">
          <img src={YouTubeButton} />
        </button>
        <div className="">
          <div className="bg-dark-prim min-h-[450px] p-4 pb-8 lg:p-4 lg:pb-16 rounded-[10px] w-full my-5">
            <iframe
              style={{
                borderRadius: "10px",
                width: "100%",
                height: "450px",
              }}
              // width="560"
              // height="315"
              src="https://www.youtube.com/embed/UVbkX_D6FQU?playlist=UVbkX_D6FQU&autoplay=1&controls=1&loop=1&mute=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; loop; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              autoPlay
            ></iframe>
          </div>
        </div>
      </div>
      <div className="carousel-section my-10">
        <Typography variant="h2">Stay up to date with the latest</Typography>
        <CarouselSection recentPosts={recentPosts} />
      </div>
      <div className="podcast-section"></div>
    </div>
  );
};
